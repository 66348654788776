import { BASE_URL } from '../app-data/ChapterData';
import { axiosInstance } from '../services/axiosService';

const chineseChars = 'chinese-chars';

const updateChineseChar = async (data) => {
  return axiosInstance.put(`${BASE_URL}/${chineseChars}/${data.id}`, data);
}

export {
  updateChineseChar
}