import React, { Component } from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import styles from './App.module.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Nav } from './components/Nav/Nav';
import { Redirect, Router } from '@reach/router';
import { CharactersManager } from './containers/CharactersManager/CharactersManager';
import { ChaptersManager } from './containers/ChaptersManager/ChaptersManager';
import CharactersManagerNew from './new/views/CharactersManager';
import LoginForm from './components/login/LoginForm';
import { StateProvider } from './states/state';
import { initialState, reducer } from './states/store';
import { initAxios } from './services/axiosService';
import { GlobalStyles } from 'twin.macro';

library.add(fas, fab, far);
initAxios();
class App extends Component {
    render() {
        return (
          <>
            <div id={'modals-container'} />
            <GlobalStyles />
            <StylesProvider injectFirst>
              <StateProvider initialState={initialState} reducer={reducer}>
                <section className={styles.charactersManager}>
                  <Nav/>
                  <section className={styles.loginForm}>
                    <LoginForm></LoginForm>
                  </section>
                  <Router>
                    <Redirect noThrow={true} from="/*" to="/characters-manager/new-characters"/>
                    <ChaptersManager path="/characters-manager/chapters"/>
                    <CharactersManager path="/characters-manager/characters"/>
                    <CharactersManagerNew path={'/characters-manager/new-characters'} />
                  </Router>
                </section>
              </StateProvider>
            </StylesProvider>
          </>
        );
    }
}

export default App;
