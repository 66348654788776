import CharacterRow from '../components/CharacterRow';
import ChapterSelector from '../components/ChapterSelector';
import { useState } from 'react';

const CharactersManager = () => {
  const [characters, setCharacters] = useState([]);

  const onResultsFound = (results) => {
    setCharacters(current => {
        return results
    });
  }

  return (
    <section>
      <h1>Characters Manager</h1>
      <section>
        <ChapterSelector
          onResultsFound={onResultsFound}
        />
      </section>
      <section>
        { characters.map((character) =>
          <CharacterRow
              key={character.id}
            { ...character}
          />
        )}
      </section>
    </section>
  )
}

export default CharactersManager;