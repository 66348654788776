/** @jsxImportSource @emotion/react */
import { Button } from '@mui/material';
import { css } from '@emotion/react';

import tw from 'twin.macro';
import { FormProvider, useForm } from 'react-hook-form';
import MultiTextField from '../FormFields/MultiTextField';
import SingleTextField from '../FormFields/SingleTextField';
import AudioField from '../FormFields/AudioField';
import { useState } from 'react';
import SearchAudioModal from '../Modal/SearchAudioModal';
import { updateChineseChar } from '../../../api/chineseChars';

const arrayOfStringsToObject = arrayOfStrings => arrayOfStrings.map(value => ({ value }));

const cssCharacterRow = () => css`
  ${tw`rounded-lg p-4 bg-white border border-solid border-gray-200 flex gap-4`}
`;

const notificationStyle = css`
  ${tw`flex justify-end pr-4`}
`;

const successStyle = css`
  ${tw`text-green-500`}
`;

const errorStyle = css`
  ${tw`text-red-500`}
`;

const CharacterRow = ({
  id,
  characters,
  pinyin,
  audio,
  dutch,
  tags,
  gif,
  gif_google_ids,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const methods = useForm({
    defaultValues: {
      id: id,
      characters: characters ? characters : '',
      pinyin: pinyin ? arrayOfStringsToObject(pinyin) : [],
      dutch: dutch ? arrayOfStringsToObject(dutch) : [],
      tags: tags ? arrayOfStringsToObject(tags) : [],
      audio: audio ? audio : [],
      gif: gif ? gif : '',
      gif_google_ids: gif_google_ids ? arrayOfStringsToObject(gif_google_ids) : []
    }
  });
  const { handleSubmit, control } = methods;

  const toggleModal = (value) => {
    setShowModal(!showModal);
  };

  const extractIdFn = (index, update, fields) => () => {
    const regex = /(.*\/d\/)(.*)(\/view)/;
    const arr = regex.exec(fields[index].value);
    if (arr && arr.length > 0) {
      update(index, {
        value: arr[2]
      })
    }
  }

  const onSubmit = async data => {
    try {
      const dataToSend = {
        id: data.id,
        pinyin: data.pinyin.map(p => p.value).filter(p => p), // map out the objects, only string array, than filter out empty values
        dutch: data.dutch.map(d => d.value).filter(d => d),
        tags: data.tags.map(t => t.value).filter(t => t),
        gif: data.gif,
        gif_google_ids: data.gif_google_ids.map(g => g.value).filter(g => g),
        audio: data.audio
      }
      await updateChineseChar(dataToSend);
      // set isSuccess for 3 seconds
      setIsSuccess(true);
      setTimeout(() => {
          setIsSuccess(false);
      }, 8000);

    } catch (err) {
      console.error('on submit', err);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 8000);
    }
  };

  const setAudioId = audioId => {
    setShowModal(false);
  };

  return (
    <>
      <FormProvider {...methods} key={id}>
        <form
          css={cssCharacterRow()}
          onSubmit={handleSubmit(onSubmit)}
        >
          <SingleTextField
            control={control}
            name={'characters'}
            label={'Characters'}
          />
          <MultiTextField
            name={'pinyin'}
            label={'Pinyin'}
          />
          <MultiTextField
            control={control}
            name={'dutch'}
            label={'Nederlands'}
          />
          <MultiTextField
            control={control}
            name={'tags'}
            label={'Tags'}
          />
          <MultiTextField
            control={control}
            name={'gif_google_ids'}
            label={'Vul Gif GOOGLE ID in'}
            formatFn={extractIdFn}
          />
          <AudioField
            control={control}
            name={'audio'}
          />
          <div>
            <Button onClick={toggleModal}>SET AUDIO</Button>
          </div>

          <div>
            <Button type="submit">SAVE</Button>
          </div>
        </form>
        <div css={notificationStyle}>
          { isSuccess && <span css={successStyle}>success</span> }
          { isError && <span css={errorStyle}>error</span> }
        </div>
      </FormProvider>
      {showModal && <SearchAudioModal onBackgroundClick={() => setShowModal(false)} setAudioCallback={setAudioId} />}
    </>
  );
}

export default CharacterRow;